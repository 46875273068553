import React from 'react'
import { About, PageWrap, SEO } from '../components'

const AboutPage = () => {
	return (
		<>
			<SEO title="About" />
			<PageWrap>
				<About />
			</PageWrap>
		</>
	)
}

export default AboutPage
